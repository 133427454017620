<template>
  <div class="row">
    <div class="col-12 col-lg-7 c g">
        <div class="card">
            <div class="card-header">
                <h5>
                    <i class="fa fa-calendar"></i>
                    الاجازات / العطلات
                    &nbsp;
                    <button class="btn btn-primary btn-sm" v-b-modal.add style="border-radius: 50%; width: 30px; height: 30px; padding:0px">
                        <i class="fa fa-plus"></i>
                    </button>
                </h5>
            </div>
            <div class="card-footer">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered">
                        <thead>
                            <th>
                                العنوان
                            </th>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                خيارات
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="a in list" :key="a._id">
                                <td>
                                    {{ a.title }}
                                    <span class="btn btn-sm btn-primary" v-if="a.from_system" style="padding: 2px;padding-left: 5px"><i class="fa fa-check"></i> بواسطة النظام</span>
                                </td>
                                <td>
                                    {{ a.date }}
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-outline-danger" @click="deleteDay(a._id)" style="border-radius: 0px;padding: 5px">
                                        <i class="fa fa-trash"></i> حذف
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="col-12 text-center">
                    <button class="btn btn-sm btn-outline-primary" v-b-modal.add2>
                        <i class="fa fa-info"></i> اضافة اجازة/اجازة مرضية لطلاب محددين
                    </button>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="add" title="اضافة اجازة" hide-footer>
        <div class="form-group">
          <label for="">اليوم</label>
          <input type="date"
            class="form-control" v-model="date">
        </div>
        <div class="form-group">
          <label for="">عنوان الاجازة</label>
          <input type="text"
            class="form-control" v-model="title" placeholder="اكتب هنا...">
          <small id="helpId" class="form-text text-muted">مثل: تعليق دراسة٫ عيد الاضحى الخ...</small>
        </div>
        <div class="col-12 text-center g text-center">
            <button class="btn btn-primary" @click="addnow()">
                اضافة الاجازة
            </button>
        </div>
    </b-modal>
    <b-modal id="add2" title="اضافة اجازة مرضية" hide-footer>
        <div class="alert alert-danger g text-center">
            سيتم احتساب تقارير الفترة المختارة للطلاب المحددين على انها غياب بعذر.
            <br>بشرط عدم تغييبهم يدوياً مستقبلاً.
        </div>
        <div class="form-group">
          <label for="">الفترة من</label>
          <input type="date"
            class="form-control" v-model="from">
        </div>
        <div class="form-group">
          <label for="">الفترة الى</label>
          <input type="date"
            class="form-control" v-model="to">
        </div>
        <div class="form-group">
          <label for="">العذر</label>
          <input type="text"
            class="form-control" v-model="title" placeholder="اكتب هنا...">
          <small id="helpId" class="form-text text-muted">مثل: اجازة مرضية، الخ...</small>
        </div>
        <div class="g">
            اختر الطلاب <input type="text" placeholder="ابحث هنا..." v-model="q" @change="getStudents()" @keyup="getStudents()" style="border: none; border: 1px solid #999; padding: 5px"> <button class="btn btn-sm"><i class="fa fa-search"></i> بحث</button>
        </div>
        <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
            <div class="form-check" v-for="student in students" :key="student._id">
            <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_students" :value="student.number">
                {{ student.name }}
            </label>
            </div>
        </div>
        <div class="col-12 text-center g text-center">
            <button class="btn btn-primary" @click="addnow2()">
                اضافة الاجازة
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            list: [],
            date: new Date().toISOString().split("T")[0],
            title: "",
            from: new Date().toISOString().split("T")[0],
            to: new Date().toISOString().split("T")[0],
            selected_students: [],
            students: [],
            q: ""
        }
    },
    created(){
        if(!checkPer("students|settings")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.getList()
        var g = this;
        g.getStudents()
    },
    methods: {
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.user.jwt,
                q: this.q,
                page: 1,
                perpage: 10000
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response
                    g.students.forEach(function(a){
                        if(!g.classrooms.includes(a.classname + ' - ' + a.classroom)){
                            g.classrooms.push(a.classname + ' - ' + a.classroom)
                        }
                    })
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        addnow(){
            var g = this;
            $.post(api + '/user/students/freedays/add', {
                jwt: this.user.jwt,
                date: this.date,
                title: this.title
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getList()
                    $("#add___BV_modal_header_ > button").click()
                }
            }).fail(function(){
            
            })
        },
        addnow2(){
            var g = this;
            if(g.selected_students.length == 0){
                return alert("قم باختيار الطلاب", 200)
            }
            $.post(api + '/user/students/freedays/add2', {
                jwt: this.user.jwt,
                selected: g.selected_students.join(","),
                from: this.from,
                to: this.to,
                title: this.title
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم اضافة الاجازة للطلاب بنجاح", 100)
                    $("#add2___BV_modal_header_ > button").click()
                }
            }).fail(function(){
            
            })
        },
        getList(){
            var g = this;
            $.post(api + '/user/students/freedays/list', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.list = r.response
                }
            }).fail(function(){
            
            })
        },
        deleteDay(id){
            if(confirm("متأكد من حذف هذا اليوم من الاجازات؟\nقد يؤثر هذا الاجراء على التقارير السابقة.")){
                var g = this;
                $.post(api + '/user/students/freedays/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getList()
                    }
                }).fail(function(){
                
                })
            }
        }
    }
}
</script>

<style>

</style>